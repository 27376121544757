import React from 'react'

// Third party
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import BlogBink, { BlogBinkPosts, useBlogBink } from '@ubo/blog-bink'

// Elements
import ParseContent from 'components/shared/ParseContent'
import LinkPrimary from 'components/elements/Links/LinkPrimary'
import HalfCircleSecondary from 'components/elements/Vectors/HalfCircleSecondary'

// Images
import Quote from 'img/quote.inline.svg'

// Interface
import { PreviewProps } from 'components/flex/Posts/Preview/PreviewShell'

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.primary};
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
`

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -998px;
  left: -40px;
  z-index: 1;
  @media (max-width: 1199px) {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    opacity: 0.4;
  }
  @media (max-width: 991px) {
    top: -800px;
    & svg {
      width: 120%;
    }
  }
`

const CaseBlock = styled.div`
  position: relative;
  overflow: hidden;
  background-color: rgba(8, 45, 83, 1);
  border-radius: 50px;
  max-width: 1440px;
  min-height: 700px;

  & h2 {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 1440px) {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  @media (min-width: 1200px) {
    padding: 7rem 3rem 2rem 9rem;
  }

  @media (max-width: 1199px) {
    padding: 1.5rem;
    border-radius: 50px;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;
  & p {
    position: relative;
    font-size: ${({ theme }) => theme.font.size.big};
    line-height: ${({ theme }) => theme.font.lineheight.mega};
    color: ${({ theme }) => theme.color.light} !important;
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  top: -55px;
  left: -55px;
`

const Col = styled.div`
  @media (max-width: 575.98px) {
    overflow: hidden;
  }
`

const Excerpt = styled(ParseContent)`
  position: relative;
  z-index: 4;
  @media (min-width: 992px) {
    & p {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      display: -webkit-box;
      overflow: hidden;
      position: relative;
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: ${({ theme }) => theme.font.lineheight.mega};
      color: ${({ theme }) => theme.color.light} !important;
    }
  }
`

const PreviewCaseImage: React.FC<PreviewProps> = ({ fields }) => {
  const {
    allWpCase,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.previewCaseImageQueryQuery>(graphql`
    query previewCaseImageQuery {
      allWpCase(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
    }
  `)

  const cases: unknown = allWpCase.edges

  return (
    <section className="mb-5 pb-5">
      {fields.order === 'pick' && (
        <BlogBink
          posts={cases as BlogBinkPosts}
          id="preview-case-image"
          limit={Infinity}
          showIds={fields.selectedcase?.map(
            ({ databaseId }: any) => databaseId
          )}
        >
          <ParseContent content={fields.description} className="mb-5" />
          <Case fields={fields} />
        </BlogBink>
      )}

      {fields.order === 'random' && (
        <BlogBink
          posts={cases as BlogBinkPosts}
          id="preview-case-image"
          limit={Infinity}
        >
          <ParseContent content={fields.description} className="mb-5" />
          <Case fields={fields} />
        </BlogBink>
      )}
    </section>
  )
}

const Case: React.FC<PreviewProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  const randomIndex = Math.floor(Math.random() * blogBink.posts.length)

  let post: any

  if (fields.order === 'random') {
    post = blogBink.posts[randomIndex].node
  }

  if (fields.order === 'pick') {
    post = blogBink.posts[0].node
  }

  const [rerender, setRerender] = React.useState(false)

  React.useEffect(() => {
    setRerender(true)
  }, [randomIndex])

  return (
    <Wrapper>
      <div className="container">
        <CaseBlock>
          {rerender && (
            <BackgroundWrapper>
              <HalfCircleSecondary image={post.recap.thumbnail} />
            </BackgroundWrapper>
          )}

          <div className="row justify-content-end">
            <Col className="position-relative col-xl-7">
              <SvgWrapper>
                <Quote />
              </SvgWrapper>
              <p className="text-white mb-5">
                <Excerpt content={post.recap.quote.description} />
              </p>
              <h2 className="position-relative" style={{ zIndex: 2 }}>
                {post.recap.quote.author}
              </h2>
              <Content
                content={post.recap.quote.authordescription}
                className="text-white"
              />
            </Col>
          </div>
          <div
            className="position-relative d-flex justify-content-end mt-5"
            style={{ zIndex: 2 }}
          >
            <LinkPrimary to={post.uri} colorPrimary arrowColor="#FFFFFF">
              {fields.readmoretext}
            </LinkPrimary>
          </div>
        </CaseBlock>
      </div>
    </Wrapper>
  )
}

export default PreviewCaseImage

/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts, useBlogBink } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

import ParseContent from 'components/shared/ParseContent'
import LinkPrimary from 'components/elements/Links/LinkPrimary'

// Images
import Location from 'img/location.inline.svg'
import Clock from 'img/clock.inline.svg'
import Suitcase from 'img/suitcase.inline.svg'
import Trencher from 'img/trencher.inline.svg'

// Interface
import { PreviewProps } from 'components/flex/Posts/Preview/PreviewShell'
import ButtonTertiary from 'components/elements/Buttons/ButtonTertiary'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const VacancyBlock = styled.div`
  position: relative;
  z-index: 2;
  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: rgba(252, 206, 20, 0.8);
  }
`

const Inner = styled.div`
  position: relative;
  z-index: 2;
  background-color: rgba(252, 206, 20, 0.8);
  mix-blend-mode: multiply;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;

  @media (min-width: 992px) {
    padding: 5rem 18rem 3rem 0;
  }

  @media (max-width: 991px) {
    padding: 2rem 1rem 8rem 1rem;
  }
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  left: 0;
  border-radius: 50px;
  filter: grayscale(100%);
  opacity: 0.15;
`

const Vacancy = styled(BlockPrimary)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & .icon {
    width: 20px;
  }

  @media (min-width: 992px) {
    width: 700px;
  }
`

const GridWrapper = styled.div`
  position: relative;
  z-index: 2;

  @media (min-width: 992px) {
    right: 260px;
    top: 60px;
  }

  @media (max-width: 991px) {
    top: -80px;
  }
`

const Excerpt = styled(ParseContent)`
  & p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
`

const PreviewVacancies: React.FC<PreviewProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.previewVacanciesQueryQuery>(graphql`
    query previewVacanciesQuery {
      allWpVacancy(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const vacancies: unknown = allWpVacancy.edges

  return (
    <Section className="mb-lg-5 pb-lg-5">
      <BlogBink
        posts={vacancies as BlogBinkPosts}
        id="preview-vacancies"
        limit={Number(fields.limit)}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <VacancyBlock>
                <Inner className="w-100 h-100">
                  <ParseContent content={fields.description} className="mb-5" />
                  <div>
                    <ButtonTertiary to="/werken-bij">
                      Bekijk alle vacatures
                    </ButtonTertiary>
                  </div>
                </Inner>
                <Image
                  image={fields.backgroundimage}
                  alt=""
                  className="w-100 h-100"
                />
              </VacancyBlock>
            </div>
            <GridWrapper className="col-lg-4">
              <VacancyGrid fields={fields} />
            </GridWrapper>
          </div>
        </div>
      </BlogBink>
    </Section>
  )
}

const VacancyGrid: React.FC<PreviewProps> = ({ fields }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post, index: number) => {
        const { node }: any = post

        return (
          <motion.div
            // initial={{ x: 110 }}
            // animate={inView && { x: 0 }}
            // transition={{ delay: index * 0.5, type: 'spring' }} R.I.P. 🥲
            key={index}
          >
            <Vacancy>
              <h2 ref={ref}>{node.title}</h2>
              <div className="d-flex flex-column flex-sm-row align-items-sm-center w-100">
                <div className="d-flex align-items-center me-4">
                  <Location className="icon" />
                  <span className="ms-2">{node.recap.location}</span>
                </div>
                <div className="d-flex align-items-center me-4">
                  <Clock className="icon" />
                  <span className="ms-2">{node.recap.hours}</span>
                </div>
                <div className="d-flex align-items-center me-4">
                  <Suitcase className="icon" />
                  <span className="ms-2">{node.recap.jobtitle}</span>
                </div>
                <div className="d-flex align-items-center me-4">
                  <Trencher className="icon" />
                  <span className="ms-2">{node.recap.education}</span>
                </div>
              </div>
              <Excerpt content={node.recap.excerpt} className="mt-5" />
              <div className="d-flex justify-content-end">
                <LinkPrimary to={node.uri}>{fields.readmoretext}</LinkPrimary>
              </div>
            </Vacancy>
          </motion.div>
        )
      })}
    </>
  )
}

export default PreviewVacancies

import React, { useState } from 'react'
import styled from 'styled-components'

import Plaatjie from '@ubo/plaatjie'
import LinkPrimary from 'components/elements/Links/LinkPrimary'
import NavLink from 'components/shared/NavLink'

import { motion } from 'framer-motion'

interface BlogPostProps {
  node: {
    id: string
    title: string
    uri: string
    recap: {
      thumbnail: any
    }
  }
  index: number
}

const Post = styled(motion.div)`
  position: relative;
  aspect-ratio: 1/1;
`

const Image = styled(Plaatjie)`
  transform: translateZ(0);
  border-radius: 25px !important;
`

const Excerpt = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(8, 46, 83, 0) 0%, #082e53 100%);
  border-radius: 25px;

  & h2 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    @media (min-width: 992px) {
      -webkit-line-clamp: 3;
    }

    @media (min-width: 576px) {
      font-size: ${({ theme }) => theme.font.size.big};
    }

    @media (max-width: 991px) {
      -webkit-line-clamp: 5;
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.small};
      -webkit-line-clamp: 4;
    }
  }
`

const BlogPost: React.FC<BlogPostProps> = ({ node, index }) => {
  const [hovered, setHovered] = useState(-1)

  return (
    <Post
      className="position-relative overflow-hidden h-100"
      onHoverStart={() => setHovered(index)}
      onHoverEnd={() => setHovered(-1)}
    >
      <NavLink to={node.uri} className="h-100">
        <motion.div
          initial={{ scale: 1 }}
          animate={hovered === index ? { opacity: 0.7 } : { opacity: 1 }}
          className="h-100"
        >
          <Image image={node.recap.thumbnail} alt="" className="h-100" />
        </motion.div>
        <Excerpt className="p-sm-4 px-2 w-100">
          <div className="d-sm-flex d-none align-items-center">
            <LinkPrimary to={node.uri}>
              <h2>{node.title}</h2>
            </LinkPrimary>
          </div>
          <div className="d-sm-none d-flex align-items-center">
            <LinkPrimary to={node.uri} arrowBottom>
              <h2>{node.title}</h2>
            </LinkPrimary>
          </div>
        </Excerpt>
      </NavLink>
    </Post>
  )
}

export default BlogPost

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

export const LinkPrimaryCSS = css`
  display: inline-flex;
  flex-wrap: wrap;

  & > a,
  & > button {
    padding: 10px 15px 10px 0;
    color: inherit !important;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & span.le-special-one {
    font-weight: ${({ theme }) => theme.font.weight.bold} !important;
    font-size: 26px !important;
    line-height: 27px !important;
  }
`

const StyledButton = styled.span<{ colorPrimary: boolean; arrowColor: string }>`
  ${LinkPrimaryCSS};

  & > a,
  & > button {
    ${(props) =>
      props.colorPrimary &&
      css`
        color: ${({ theme }) => theme.color.primary} !important;
      `}
  }

  & svg {
    ${(props) =>
      props.arrowColor !== '' &&
      css`
        & path {
          fill: ${props.arrowColor};
        }
      `}
  }
`

interface LinkPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  arrowBottom?: boolean
  colorPrimary?: boolean
  arrowColor?: string
  to: string
  className?: string
  target?: string
}

const LinkPrimary: React.FC<LinkPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  arrowBottom = false,
  arrowColor = '',
  colorPrimary = false,
  to,
  children,
  className = '',
  target = '',
}) => {
  const [isHover, setHover] = useState<boolean>(false)

  return (
    <motion.div
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <StyledButton
        className={className}
        colorPrimary={colorPrimary}
        arrowColor={arrowColor}
      >
        {isCustom ? (
          <>
            <motion.span
              animate={isHover ? { x: 150 } : { x: 0 }}
              className="d-flex"
            >
              <ArrowRight className="me-3" />
            </motion.span>
            {children}
          </>
        ) : (
          <ButtonShell to={to} isAnchor={isAnchor} target={target}>
            <span
              className={`d-flex align-items-${
                arrowBottom ? 'start flex-column' : 'center'
              }`}
            >
              {children}

              <motion.span
                animate={isHover ? { x: 10 } : { x: 0 }}
                className="d-flex"
              >
                <ArrowRight className={`${arrowBottom ? 'ms-0' : 'ms-3'}`} />
              </motion.span>
            </span>
          </ButtonShell>
        )}
      </StyledButton>
    </motion.div>
  )
}

export default LinkPrimary

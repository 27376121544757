import React from 'react'

// Third party
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import BlogPost from 'components/flex/Posts/Layout/BlogPost'

// Images
import BackgroundCircle from 'img/backgroundcircle.inline.svg'

// Interface
import { PreviewProps } from 'components/flex/Posts/Preview/PreviewShell'

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: -580px;
  left: 0;
  z-index: -1;
  transform: rotate(180deg);
`

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
`

const PreviewBlog: React.FC<PreviewProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.previewBlogQueryQuery>(graphql`
    query previewBlogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <Section className="position-relative mb-lg-5 pb-5">
      {fields.backgroundarch && (
        <BackgroundWrapper>
          <BackgroundCircle />
        </BackgroundWrapper>
      )}
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="preview-blog"
        limit={fields.order === 'latest' ? Number(fields.limit) : Infinity}
        showIds={fields.selectedblogs?.map(({ databaseId }: any) => databaseId)}
      >
        <div className="py-lg-5">
          <ParseContent content={fields.description} />
          {/* @ts-ignore */}
          <BlogGrid fields={fields} />
        </div>
        {fields.link && (
          <div className="d-flex justify-content-center mt-5 mt-lg-0">
            <ButtonPrimary to={fields.link?.url}>
              {fields.link?.title}
            </ButtonPrimary>
          </div>
        )}
      </BlogBink>
    </Section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row justify-content-center">
        {blogBink.posts.length > 0 &&
          blogBink.posts.map((post, index) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-6 col-md-4 mt-5">
                <BlogPost node={node} index={index} />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default PreviewBlog

import React from 'react'

// Components
import PreviewCase from 'components/flex/Posts/Preview/PreviewCase'
import PreviewVacancies from 'components/flex/Posts/Preview/PreviewVacancies'
import PreviewBlog from 'components/flex/Posts/Preview/PreviewBlog'
import PreviewCaseImage from 'components/flex/Posts/Preview/PreviewCaseImage'

export interface PreviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PreviewPostTypes
  location?: any
}

interface PostProps {
  [key: string]: any
}

const PreviewShell: React.FC<PreviewProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    case: PreviewCase,
    caseimage: PreviewCaseImage,
    vacancies: PreviewVacancies,
    blog: PreviewBlog,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default PreviewShell

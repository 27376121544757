import React from 'react'

const HalfCircleSecondary = ({ image }: any) => (
  <svg
    width="761"
    height="2518"
    viewBox="0 0 761 2518"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <mask
      id="maskhalfcirclesecondary"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="-1757"
      y="0"
      width="2518"
      height="2518"
    >
      <path
        d="M150.917 954.771C143.168 1073.25 106.685 1188.06 44.6194 1289.28C-57.4625 1455.73 -221.4 1574.88 -411.225 1620.6C-601.05 1666.32 -801.26 1634.87 -967.929 1533.16C-1069.27 1471.3 -1154.03 1385.69 -1214.87 1283.73L-1651.23 1388.83C-1554.8 1602.92 -1395.98 1782.9 -1195.55 1905.21C-643.987 2242.65 79.23 2068.46 416.669 1516.9C539.426 1316.74 598.888 1084.19 587.272 849.673L150.917 954.771Z"
        fill="#F4F4F4"
      />
    </mask>
    <g mask="url(#maskhalfcirclesecondary)">
      <rect
        x="-160"
        y="998"
        width="1061"
        height="1000"
        fill="url(#patternhalfcirclesecondary)"
      />
    </g>
    <defs>
      <pattern
        id="patternhalfcirclesecondary"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#imagehalfcirclesecondary"
          transform="scale(0.000182749)"
        />
      </pattern>
      <image
        id="imagehalfcirclesecondary"
        width="5472"
        height="3830"
        xlinkHref={image?.localFile?.publicURL}
      />
    </defs>
  </svg>
)

export default HalfCircleSecondary
